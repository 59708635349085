<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <div class="host-body">
        <Menu name="postion"
              @change="onProInfoChange($event)" />

        <div id="content">
          <!-- 左边 -->

          <div id="left">
            <div class="top">
              <div class="item fl">
                <a-range-picker v-model="queryParam.RecordDate"
                                :show-time="{ format: 'HH:mm' }"
                                format="YYYY-MM-DD HH:mm"
                                :placeholder="['开始时间', '结束时间']"
                                :showTime="true"
                                :showToday="true"
                                style="width: 100%"
                                @ok="dateSelected">
                  <template slot="dateRender"
                            slot-scope="current">
                    <div class="ant-calendar-date"
                         :style="getCurrentStyle(current)">
                      {{ current.date() }}
                    </div>
                  </template>
                </a-range-picker>
              </div>
            </div>
            <a-tree v-if="devices && devices.length"
                    default-expand-all
                    :treeData="devices"
                    style="max-height: 10.5rem;  overflow: auto;"
                    @select="Selected">
            </a-tree>
            <div v-else>
              <a-empty />
            </div>
          </div>

          <dv-loading v-if="loading">Loading...</dv-loading>
          <div id="center"
               v-else>
            <baidu-map class="map"
                       :center="center"
                       :zoom="zoom"
                       :map-click="false"
                       :scroll-wheel-zoom="true">
              <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
              <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
                           anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>

              <!-- 添加起点标记 -->
              <bm-marker :position="start"
                         animation="BMAP_ANIMATION_BOUNCE"
                         :icon="{ url: startImg, size: { 'width': '32px', 'height': '32px' }, opts: { anchor: { width: 16, height: 16 } } }"></bm-marker>

              <!-- 添加终点标记 -->
              <bm-marker :position="end"
                         animation="BMAP_ANIMATION_BOUNCE"
                         :icon="{ url: endImg, size: { 'width': '32px', 'height': '32px' }, opts: { anchor: { width: 16, height: 16 } } }"></bm-marker>
              <!-- 折线 -->
              <bm-polyline :path="polygonPath.paths"
                           stroke-color="#4aa4f3"
                           :stroke-opacity="1"
                           :stroke-weight="6"
                           stroke-style="dashed"
                           @lineupdate="updatePolylinePath" />
              <!-- 运行轨迹的路线 -->
              <!-- <template v-if="polygonPath.editing">
                <bm-polygon :path="path"
                            v-for="path of polygonPath.paths"
                            :key="path.toString()"
                            stroke-color="#1492FF"
                            :fill-opacity="0.2"
                            :stroke-opacity="1"
                            :stroke-weight="3"
                            @click="alertpath" />
              </template> -->
              <bml-lushu @stop="reset"
                         :path="polygonPath.paths"
                         :icon="icon"
                         :play="play"
                         :rotation="false"
                         :speed="controlSpeed"> </bml-lushu>

              <!-- <bm-control>
                <a-button type="danger"
                          size="small"
                          @click="toggle('polygonPath')">
                  {{ polygonPath.editing ? "停止绘制" : "开始绘制" }}
                </a-button>
              </bm-control> -->
              <bm-marker :position="{ lng: center.lng, lat: center.lat }"
                         :dragging="false"
                         animation="BMAP_ANIMATION_BOUNCE">
              </bm-marker>
              <bm-view style="width: 100%; height:100%;"></bm-view>
            </baidu-map>
          </div>
          <!-- <div id="right"></div> -->
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
moment.locale("zh-cn");
import moment from "moment";
import ProInfoCache from "@/utils/cache/ProInfoCache";
import Menu from "@/components/Menu";
import personImg from '@/assets/image/person.png'
import startImg from '@/assets/image/start.png'
import endImg from '@/assets/image/end.png'
import {
  BaiduMap,
  BmView,
  BmlLushu,
  BmMarker
} from 'vue-baidu-map'

export default {
  data () {
    return {
      loading: true,
      visible: false,
      pro: {},
      entity: {},
      data: [],
      connection: {},
      queryParam: {},
      bgImg: "url(" + require("@/assets/image/morenditu.png") + ")",
      devices: {},
      currentKey: 0,
      mapStyle: {
        width: '100%',
        height: '100%'
      },
      center: {
        lat: 29.636146,
        lng: 106.481882
      },
      zoom: 19,
      polygonPath: {
        editing: false,
        paths: [] // 绘制完成后的经纬度，其实是在画的时候动态push的，因为在点击的时候触发了 paintPolygon 函数
      },
      personImg,
      startImg,
      endImg,
      start: {
        lat: 29.636146,
        lng: 106.481882
      },
      end: {
        lat: 29.636146,
        lng: 106.481882
      },
      icon: { url: personImg, size: { 'width': '32px', 'height': '32px' }, opts: { anchor: { width: 16, height: 16 } } },
      play: false, // 是否行进

      deviceId: '',
      drawAreaPos: []
    };
  },
  components: {
    Menu,
    BaiduMap,
    BmView,
    BmlLushu,
    BmMarker
  },

  created: function () {
    let connection = this.$op.getConnect(this);
    connection.on("gpslocation", (x) => {
      console.log("gpslocation")
      console.log(x)
      if (x.pid != this.pro.Id)
        return

    });
  },

  computed: {
    // 终点计算
    endPoint () {
      if (this.path && this.path.length > 0) {
        return this.path[this.path.length - 1]
      }
      return null
    },
    controlSpeed () {
      if (this.zoom < 5) {
        return 1000
      }
      if (this.zoom >= 5 && this.zoom < 10) {
        return 400
      }
      if (this.zoom >= 10 && this.zoom < 15) {
        return 100
      }
      if (this.zoom >= 15 && this.zoom < 20) {
        return 40
      }
      if (this.zoom >= 20) {
        return 20
      }
      return 19
    }
  },
  mounted () {
    this.pro = ProInfoCache.getProInfo();
    this.initForm()
  },
  watch: {},
  methods: {
    onProInfoChange (e) {
      this.pro = e;
      this.initForm()
    },
    initForm () {
      this.fetchEventsList();
      this.loadData();
      this.cancelLoading();
    },
    loadData () {
      this.$http
        .post("/Projects/ProBase/GetTheData", {
          id: this.pro.Id,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success && resJson.Data) {
            this.entity = resJson.Data
            if (this.entity && this.entity.Pos) { this.center = { lat: this.entity.Pos.Lat, lng: this.entity.Pos.Lng } }

          }
        });
    },
    fetchEventsList () {
      if (!this.pro.Id) return;
      //this.loading = true;
      this.$http
        .post("/DeviceManage/DeviceSafetyhelmetGpsGroup/GetTreeDataList", {
          ProId: this.pro.Id,
        })
        .then((resJson) => {
          //  this.loading = false;
          this.devices = resJson.Data;
        });
    },
    getDataList () {
      //this.loading = true
      this.$http
        .post('/DeviceManage/DeviceSafetyhelmetGps/GetPlayBackData', this.queryParam)
        .then((resJson) => {
          //this.loading = false
          this.data = resJson.Data
          this.initPoint(resJson.Data)
        })
    },
    Selected (selectedKeys) {
      this.queryParam.DeviceId = selectedKeys[0]
      this.getDataList()
    },
    dateSelected () {
      this.fetchEventsList()
    },
    getCurrentStyle (current) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    cancelLoading () {
      setTimeout(() => {
        this.fetchEventsList();
      }, 10 * 60 * 60 * 1000);
    },

    initPoint (points) {
      this.polygonPath = {
        editing: false,
        paths: [] // 绘制完成后的经纬度，其实是在画的时候动态push的，因为在点击的时候触发了 paintPolygon 函数
      }
      if (points && points.length > 0) {
        const path = []
        points.forEach(item => {
          var x = this.gaoDeToBaidu(item.Longitude, item.Latitude)
          //path.push(new BMap.Point(x.lng, x.lat))// { lat: item.Lat, lng: item.Lng }
          path.push(x)
        })
        this.polygonPath.paths = points.map(item => {
          return this.gaoDeToBaidu(item.Longitude, item.Latitude)
        })
        this.play = true
        if (this.polygonPath.paths.length > 0) {
          this.center = this.polygonPath.paths[0]
          this.start = this.polygonPath.paths[0]
          this.end = this.polygonPath.paths[this.polygonPath.paths.length - 1]
        }
      }
    },
    // 覆盖物的属性发生变化时触发
    updatePolylinePath (e) {
      this.polygonPath.paths = e.target.getPath()
    },
    // 停止本次移动
    reset () {
      this.play = false
    },
    // 高德坐标转换百度坐标
    gaoDeToBaidu (gd_lon, gd_lat) {
      const PI = 3.14159265358979324 * 3000.0 / 180.0
      const x = gd_lon; const y = gd_lat
      const z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * PI)
      const theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * PI)
      const lng = z * Math.cos(theta) + 0.0065// 经度
      const lat = z * Math.sin(theta) + 0.006// 纬度
      return { lng, lat }
    },
    handler ({ BMap, map }) {
      console.log(BMap, map)
    },

  },
};
</script>

<style lang="scss">
.danger {
  color: #fb497c !important;
}
.noml {
  color: #24e974 !important;
}
.huang {
  color: #ffb70e !important;
}
#content {
  display: flex;
  width: 100%;
  padding: 0.5rem 0.3rem 0 0.3rem;
  height: 10.3rem;
  overflow: hidden;
  h3 {
    font-weight: 900;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgb(249 242 242 / 85%);
  }
  #left {
    width: 2.94rem;
    height: 100%;
    margin-left: 1.4%;

    background: #0f1325;
    .ant-tree-switcher,
    .ant-tree-icon__customize {
      color: #1a5cd7;
    }
    .ant-tree-title {
      color: #1a5cd7;
      width: 2.14rem;
      overflow: hidden; /*溢出的部分隐藏*/
      white-space: nowrap; /*文本不换行*/
      text-overflow: ellipsis; /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
    }
  }

  #center {
    width: 1430px;
    height: 10.3rem;
    margin-left: 1.4%;
  }
}
.mapContainer {
  // width: auto;
  height: 100%;
  margin: 0 auto;
  background: #03263d;
  position: relative;
}
.monitor-device-item-link {
  cursor: pointer;
  background-color: rgb(15 19 37);
  margin: 0.025rem 0px;
  height: 0.35rem;
}
.active,
.monitor-device-item-link:hover {
  background-color: #1a5cd7;
}
.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -moz-box;
}
.align-center {
  align-items: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  text-align: center;
}
.trans-o-left {
  padding-left: 8.61111px;
  transform: scale(0.645833);
  font-size: 10.5px;
  color: hsla(0, 0%, 100%, 0.8);
  transform-origin: left center 0;
  -webkit-transform-origin: left center 0;
  -ms-transform-origin: left center 0;
}
.position {
  cursor: pointer;
  /*  position: absolute; */
  top: 179px;
  left: 661px;
  width: 40px;
  height: 40px;
  z-index: 1000;
  color: #fff;
  text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  -webkit-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  -moz-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
}
.align-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.map {
  width: 100%;
  height: 90%;
}
</style>
